<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-end">
      <router-link tag="div" to="/supplier/add">
        <l-button class="bg-brand rounded text-white" :text="$t('addSupplier')">
          <template v-slot:icon>
            <PlusIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          </template>
        </l-button>
      </router-link>
    </div>
    <div class="flex flex-wrap items-centre space-x-2 justify-end py-3">
      <alpha-pagination
        @input="onAlphaChanged"
        :value="currentAlpha"
      ></alpha-pagination>
      <form @submit="onSearchSubmit">
        <label class="block text-left">
          <input
            type="text"
            v-model="searchQuery"
            class="
              mt-1
              block
              w-full
              rounded-md
              bg-gray-200
              border-transparent
              focus:border-gray-500 focus:bg-white focus:ring-0
            "
            placeholder="search"
          />
        </label>
      </form>
    </div>
    <div class="flex py-4">
      <l-table>
        <template #thead>
          <l-t-head>
            <l-tr>
              <l-th> {{ $t("supplierName") }} </l-th>
              <l-th> {{ $t("supplierCompany") }} </l-th>
              <l-th> {{ $t("mobileNumber") }} </l-th>
              <l-th> {{ $t("status") }} </l-th>
              <l-th> {{ $tc("action", 2) }} </l-th>
            </l-tr>
          </l-t-head>
        </template>
        <template #tbody>
          <l-t-body>
            <l-tr :key="index" v-for="(supplier, index) in suppliers">
              <l-td>
                <p>{{ supplier.full_name }}</p>
              </l-td>
              <l-td>
                <p class="capitalize">{{ supplier.company }}</p>
              </l-td>
              <l-td>
                <p class="capitalize">{{ supplier.mobile }}</p>
                <p class="capitalize">{{ supplier.telephone }}</p>
              </l-td>
              <l-td>
                <div class="flex justify-start items-start text-left">
                  <l-chip
                    v-if="supplier.status === 1"
                    :text="$t('active')"
                    type="success"
                  ></l-chip>
                  <l-chip
                    v-if="supplier.status === 0"
                    :text="$t('inActive')"
                    type="danger"
                  ></l-chip>
                </div>
              </l-td>
              <l-td>
                <div class="flex flex-row space-x-2">
                  <router-link :to="`/supplier/${supplier.supplier_id}`">
                    <l-button :text="$t('edit')" type="outline"></l-button>
                  </router-link>
                  <l-button
                    @click="deactivateSupplier(supplier)"
                    :text="
                      supplier.status === 1 ? $t('deactivate') : $t('activate')
                    "
                    :type="supplier.status === 1 ? 'danger' : 'success'"
                  ></l-button>
                </div>
              </l-td>
            </l-tr>
          </l-t-body>
        </template>
      </l-table>
    </div>
    <div class="items-center flex justify-center" v-if="totalPages > 0">
      <l-pagination
        :max="totalPages"
        :value="activatePage"
        @input="pageUpdated"
      ></l-pagination>
    </div>
  </div>
</template>

<script>
import { default as toast } from "@/utils/toastUtils";
import { PlusIcon } from "@heroicons/vue/outline";
import LButton from "@/components/custom/LButton";
import LTable from "@/components/custom/table/LTable";
import LTHead from "@/components/custom/table/LTHead";
import LTr from "@/components/custom/table/LTr";
import LTd from "@/components/custom/table/LTd";
import LTh from "@/components/custom/table/LTh";
import LTBody from "@/components/custom/table/LTBody";
import { mapGetters } from "vuex";
import LPagination from "@/components/custom/pagination/LPagination";
import AlphaPagination from "@/components/custom/AlphaPagination";
import LChip from "@/components/custom/chip/LChip";

export default {
  name: "SuppliersList",
  components: {
    LChip,
    AlphaPagination,
    LPagination,
    LTable,
    LTHead,
    LButton,
    PlusIcon,
    LTr,
    LTh,
    LTd,
    LTBody,
  },
  data() {
    return {
      activeTab: 0,
      activatePage: 1,
      searchQuery: "",
      currentAlpha: "",
    };
  },
  computed: {
    ...mapGetters(["suppliers", "suppliersFilters"]),
    totalPages() {
      let total = this.suppliersFilters.total / this.suppliersFilters.limit;

      if (total) {
        return Math.ceil(total);
      } else {
        return 0;
      }
    },
  },
  methods: {
    onSearchSubmit(e) {
      e.preventDefault();
      this.currentAlpha = "";
      this.fetchSuppliers();
    },
    onAlphaChanged(value) {
      this.currentAlpha = value;
      this.searchQuery = "";
      this.fetchSuppliers();
    },
    prepareGetData() {
      let data = {
        page: this.activatePage - 1,
      };
      if (this.searchQuery !== "") {
        data["search_text"] = this.searchQuery;
      }
      if (this.currentAlpha !== "") {
        data["search_text"] = this.currentAlpha;
      }
      return data;
    },
    fetchSuppliers() {
      this.$store.dispatch("fetchSuppliersList", this.prepareGetData());
    },
    pageUpdated(value) {
      this.activatePage = value;
      this.fetchSuppliers();
    },

    deactivateSupplier(supplier) {
      let data = {
        status: supplier.status === 1 ? 0 : 1,
        supplier_id: supplier.supplier_id,
      };
      this.$store
        .dispatch("updateSupplier", data)
        .then((data) => {
          toast.success(data.message);
          this.fetchSuppliers();
        })
        .catch((err) => {
          toast.error(err);
        });
    },
  },
  mounted() {
    this.fetchSuppliers();
  },
};
</script>

<style scoped></style>
