<template>
  <div class="bg-white px-6 py-6 space-y-5 rounded-lg shadow container mx-auto">
    <h2 class="text-3xl font-bold text-left">
      {{ isEdit ? $t("editSupplier") : $t("addNewSupplier") }}
    </h2>
    <TransitionRoot
      :show="showForm"
      enter="transition ease-in-out duration-300 transform"
      enter-from="-translate-x-full"
      enter-to="translate-x-0"
      leave="transition ease-in-out duration-300 transform"
      leave-from="translate-x-0"
      leave-to="-translate-x-full"
    >
      <Form
        @submit="onSubmitSupplier"
        v-slot="{ meta }"
        :validation-schema="schema"
        class="flex flex-col w-full space-y-5 items-start"
      >
        <div class="grid grid-cols-2 gap-6 text-left w-full">
          <div class="flex items-start flex-col w-full space-y-4">
            <l-input-field
              :label="$t('fullName')"
              name="fullName"
              type="text"
              :value="fullName"
            >
            </l-input-field>
            <l-input-field
              :label="$t('company')"
              name="company"
              type="text"
              :value="company"
            >
            </l-input-field>
            <l-input-field
              :label="$t('address')"
              name="address"
              type="text"
              :value="address"
            >
            </l-input-field>
            <l-input-field
              :label="$t('emailId')"
              name="emailId"
              type="email"
              :value="emailId"
            >
            </l-input-field>
            <l-input-field
              :label="$t('mobile')"
              name="mobile"
              type="number"
              :value="mobile"
            >
            </l-input-field>
            <l-input-field
              :label="$t('stNr')"
              name="stNr"
              type="text"
              :value="stNr"
            >
            </l-input-field>
            <l-input-field
              :label="$t('telephone')"
              name="telephone"
              type="number"
              :value="telephone"
            >
            </l-input-field>
          </div>
          <div class="flex flex-col w-full space-y-4">
            <label class="w-full">
              <span class="text-gray-700">Status</span>
              <Field
                name="status"
                as="select"
                v-model="status"
                class="
                  block
                  w-full
                  mt-1
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
              >
                <option value="1">Active</option>
                <option value="0">In Active</option>
              </Field>
            </label>
            <l-input-field
              :label="$t('ustIdr')"
              name="ustIdr"
              type="text"
              :value="ustIdr"
            >
            </l-input-field>
            <l-input-field
              :label="$t('city')"
              name="city"
              type="text"
              :value="city"
            >
            </l-input-field>
            <l-input-field
              :label="$t('country')"
              name="country"
              type="text"
              :value="country"
            >
            </l-input-field>
            <l-input-field
              :label="$t('postalCode')"
              name="postalCode"
              type="text"
              :value="postalCode"
            >
            </l-input-field>

            <label class="block w-full">
              <span class="text-gray-700">{{ $tc("remark", 2) }}</span>
              <Field
                as="textarea"
                name="remarks"
                v-model="remarks"
                class="
                  mt-1
                  block
                  w-full
                  rounded-md
                  bg-gray-100
                  border-transparent
                  focus:border-gray-500 focus:bg-white focus:ring-0
                "
                rows="3"
              ></Field>
              <ErrorMessage class="text-red-500" name="remarks" />
            </label>
          </div>
        </div>
        <div class="flex w-full justify-center items-center space-x-4">
          <button
            @click="$router.go(-1)"
            class="
              bg-red-500
              cursor-pointer
              rounded
              px-4
              py-2
              text-white
              font-bold
            "
          >
            {{ $t("cancel") }}
          </button>
          <button
            type="submit"
            class="bg-brand rounded px-4 py-2 text-white font-bold"
            :class="{
              'opacity-50 cursor-not-allowed': !meta.valid,
              'opacity-100 cursor-pointer': meta.valid,
            }"
          >
            {{ isEdit ? $t("update") : $t("submit") }}
          </button>
        </div>
      </Form>
    </TransitionRoot>
  </div>
</template>

<script>
import { default as toast } from "@/utils/toastUtils";
import {
  useForm,
  useIsFormValid,
  Field,
  Form,
  ErrorMessage,
} from "vee-validate";
import * as yup from "yup";
import LIconButton from "@/components/custom/LIconButton";
import { TrashIcon } from "@heroicons/vue/outline";
import { TransitionRoot } from "@headlessui/vue";
import LInputField from "@/components/custom/LInputField";

export default {
  name: "AddProduct",
  components: { LInputField, Field, Form, ErrorMessage, TransitionRoot },
  setup() {
    // Define a validation schema
    const schema = yup.object({
      fullName: yup.string().required().min(4),
      company: yup.string().required(),
      address: yup.string().required().min(4),
      emailId: yup.string().email().required(),
      mobile: yup.number().required().min(4),
      stNr: yup.string().required().min(4),
      status: yup.number().required(),
      ustIdr: yup.string().required(),
      city: yup.string().required(),
      country: yup.string().required(),
      postalCode: yup.number("Invalid").required().min(4),
    });

    const { meta, errors } = useForm({
      validationSchema: schema,
    });

    return {
      schema,
      meta,
    };
  },
  props: {
    supplierId: {
      default: "",
      type: [String],
    },
  },
  data() {
    return {
      fullName: "",
      company: "",
      address: "",
      emailId: "",
      mobile: "",
      stNr: "",
      telephone: "",
      status: "1",
      ustIdr: "",
      city: "",
      country: "",
      postalCode: "",
      remarks: "",
      showForm: false,
    };
  },
  computed: {
    isEdit() {
      return this.supplierId !== "";
    },
  },
  methods: {
    onSubmitSupplier(values) {
      let data = {
        full_name: values.fullName,
        company: values.company,
        address: values.address,
        email: values.emailId,
        mobile: values.mobile,
        telephone: values.telephone,
        stnr: values.stNr,
        ustidnr: values.ustIdr,
        city: values.city,
        country: values.country,
        postalcode: values.postalCode,
        remarks: values.remarks,
      };
      if (this.isEdit) {
        data["supplier_id"] = this.supplierId;
        this.$store
          .dispatch("updateSupplier", data)
          .then((data) => {
            toast.success(data.message);
            this.$router.push({ name: "suppliers" });
          })
          .catch((err) => {
            toast.error(err);
          });
      } else {
        this.$store
          .dispatch("addNewSupplier", data)
          .then((data) => {
            toast.success(data.message);
            this.$router.push({ name: "suppliers" });
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    },
    checkSupplier() {
      if (this.supplierId) {
        this.$store
          .dispatch("fetchSupplier", { supplier_id: this.supplierId })
          .then((response) => {
            let data = response.supplier;
            console.log(data);
            this.fullName = data.full_name || "";
            this.company = data.company || "";
            this.address = data.address || "";
            this.emailId = data.email || "";
            this.mobile = data.mobile || "";
            this.stNr = data.stnr || "";
            this.telephone = data.telephone || "";
            this.status = data.status.toString() || "";
            this.ustIdr = data.ustidnr || "";
            this.city = data.city || "";
            this.country = data.country || "";
            this.postalCode = data.postalcode || "";
            this.remarks = data.remarks || "";

            this.showForm = true;
          });
      } else {
        this.showForm = true;
      }
    },
  },
  created() {
    this.checkSupplier();
  },
};
</script>

<style scoped></style>
