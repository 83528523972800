<template>
  <div
    class="
      rounded-full
      w-min-content
      text-black
      px-2
      font-medium
      flex
      justify-center
      bg-opacity-50
    "
    :class="getChipClass()"
  >
    <slot name="icon"></slot>
    <p>{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "LChip",
  props: {
    text: {
      default: "",
      type: String,
    },
    type: {
      default: "",
      type: String,
    },
  },
  methods: {
    getChipClass() {
      if (this.type === "success") {
        return "bg-green-400";
      }
      if (this.type === "danger") {
        return "bg-red-400";
      }
      if (this.type === "info") {
        return "bg-blue-400";
      }
      if (this.type === "warning") {
        return "bg-yellow-400";
      }
      return "bg-brand";
    },
  },
};
</script>

<style scoped></style>
